@use '@involi/components';

@include components.inv-core();

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
:focus
{
    outline: none;
}

h1
{
    border-bottom: 1px solid #bebebe;
}

.spacer
{
    flex: 1 0 auto;
}