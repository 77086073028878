@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

$font-family: 'Inter';
$font-sizes: (
    links: 16px,
    small-links: 12px,
    text: 14px,
    small-text: 12px,
    large-text: 16px,
    header1: 20px,
    header2: 18px,
    header3: 16px,
    header4: 14px,
    header5: 12px
);
$line-heights: (
    links: 22px,
    small-links: 12px,
    text: 22px,
    small-text: 16px,
    large-text: 26px,
    header1: 24px,
    header2: 22px,
    header3: 20px,
    header4: 20px,
    header5: 18px
);

@mixin font($category)
{
    font-weight: 400;
    font-size: map-get($font-sizes, $category);
    font-family: $font-family;
    font-style: normal;
    line-height: map-get($line-heights, $category);
}

@mixin header($category)
{
    @include font($category);
    font-weight: 600;
}

@mixin inv-typography()
{
    h1 { @include header(header1); }
    h2 { @include header(header2); }
    h3 { @include header(header3); }
    h4 { @include header(header4); }
    h5 { @include header(header5); }
}
// Color tokens
$color-primary: #648aee;
$color-primary-hover: #5374da;
$color-primary-active: #2047ac;
$color-secondary-black: #101010;
$color-secondary-dark-grey: #464646;
$color-secondary-medium-grey: #646464;
$color-secondary-light-blue: #DEE3EC;
$color-secondary-medium-blue: #878EA1;
$color-secondary-white: #FFFFFF;
$color-ui-primary: #FFFFFF;
$color-ui-secondary: #242526;
$color-ui-light: #F4F4F4;
$color-overlay-dark: #101010B2;
$color-highlight-light: #F5F8FF;
$color-highlight-medium-light: #E4ECFF;
$color-highlight-yellow: #FFD706;
$color-warning: #EA0000;
$color-success: #58C900;
$color-status-online: #E0FCCA;
$color-status-offline: #F4F4F4;
$color-status-error: #FFCDCD;
$color-status-in-progress: #FEEFD5;
$color-working-receiver: #58C900;
$color-disabled-receiver: #878EA1;
$color-error-receiver: #EA0000;
$color-aircraft-tracker: #8886FF;
$color-map-selected-element: #FFD706;
$color-line: #5553DA;
$color-filler: #5553DA4C;

// Color usage
$color-primary-text: $color-secondary-black;
$color-secondary-text: $color-secondary-dark-grey;
$color-placeholder-text: $color-secondary-medium-blue;
$color-empty-state-text: $color-secondary-medium-grey;
$color-divider: $color-secondary-light-blue;
$color-danger: $color-warning;
$color-danger-accent: #b80000;
$color-light-icon: $color-secondary-medium-grey;
$color-light-icon-border: $color-divider;
$color-dark-icon-hover: #3e3f3f;
$color-selected-element: $color-primary-hover;
$color-hover-element: $color-highlight-light;
$color-background: $color-ui-primary;
$color-error: $color-warning;
$color-shadow: rgba(58, 58, 58, 0.12);
$color-menu-shadow: rgba(0, 0, 0, 0.27);
$opacity-disabled: 0.6;

@mixin inv-core()
{
    @include inv-typography();
}
.inv-dialog-panel .mat-dialog-container
{
    padding: 0;
}

